@import "../../../../../styles/color.module.scss";

.modalBody{
  min-height: 12vh;
  max-height: 50vh;
  overflow-y: auto;

  & div.spinnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.refreshButton {
  margin-right: 2rem;
}

.tableRowSelected > div {
  background-color: rgba($dusk-70, 0.1) !important;
  margin-bottom: -2px;
  -webkit-box-shadow: inset 0px 0px 0px 2px $dusk-70;
  -moz-box-shadow: inset 0px 0px 0px 2px $dusk-70;
  box-shadow: inset 0px 0px 0px 2px $dusk-70;
  border-radius: 4px;
  z-index: 1;
  cursor: default;
}

.tableRowDisabled > div {
  opacity: 0.6;
}
