@import "../../../styles/color.module.scss";

.flx-card__body .table-data__container {
  width: 100% !important;
}

.wrapListTable {
  width: 100% !important;
}
.overTime {
  color: $secondary-berry;
  display: inline;
}

.aRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.aColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.fixcolumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0.3 1;
  vertical-align: middle;
  align-items: center;
  padding-top: 25px;
}

.inputWrapper {
  width: 170px;
  padding-right: 16px;
}

.selectWrapper {
  width: 100%;
}

ul {
  list-style-type: none !important;
}

.commentView {
  font-family: "NeutroMYOB", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #2e3e4f;
}

.error {
  vertical-align: middle;
  padding-top: 10px;
  height: 172px;
  width: 129px;
  border-color: #ce255460;
  border-style: solid;
  text-align: center;
  border-radius: 4px;
}

.errorIcon {
  padding-top: 30px;
  padding-bottom: 8px;
  svg {
    fill: #ce255480;
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  align-items: center;
  justify-items: center;

  .gridItem {
    padding-top: 10px;
    img {
      height: 172px;
      width: 129px;
      border-radius: 4px;
    }
  }
}
