.emptyListContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 24%;
    margin-right: 24%;
    text-align: center;

  > div {
    margin: 0;
  }
}
