// This cheeky little bit of code is a genius work around to overwrite
// Feelix styles while still localy scoping the changes just to the
// screen
// credit :- James Pickering
.rosterPage {
  background-color: transparent;

  :global .grid-cell__lg {
    width: 195px;
    min-width: 195px;
    flex-grow: unset;
    flex-basis: 195px;
  }

  :global .grid-footer .grid-cell {
    font-size: 14px;
  }

  :global .filter-bar--sm .filter-bar__inner .flx-datepicker {
    width: 100%;
  }
}

.inputWrapper {
  width: 190px;
  padding-right: 16px;
}
.weekSelect {
  width: 185px !important;
  max-width:190px !important;
}
.headerText {
  font-weight: 600;
}

.headerAlignLeft {
  @extend .headerText;

  text-align: right;
}

.rowTitle {
  padding-left: 22px;
}

.rowTotal {
  text-align: right;
}

.cellPadding {
  padding: 0 4px;
}

.footerText {
  font-size: 14;
  font-weight: 200;
}

.footerTextTitle {
  padding-left: 22px;
  font-weight: 200;
}

.footerTextTotal {
  text-align: right;
  font-weight: 200;
}

.publishContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0 1.6rem 4rem;
}

.emptyWrapper {
  padding: 40px;
  display: flex;
  justify-content: center;
}

.emptyContent {
  max-width: 400px;
  text-align: center;
  h3 {
    padding: 8px;
    margin: 0;
  }
}

.emptyIllustrationWrapper {
  max-width: 200px;
  margin: 20px auto;
}
