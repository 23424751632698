@import "../../../../styles/color.module.scss";

.accepted {
  color: $golf;
}

.pending {
  color: $thunder-black;
}

.notSent {
  color: $secondary-berry;
}

.inviteCell {
  font-weight: 700;
}

.managementControls {
  border-bottom: 0.15em $storm-17;
  padding-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    margin-right: 0.5em;
  }
}

.tableRow > div {
  margin-bottom: -2px;
  cursor: default;
}

.tableHeading {
  padding-right: 0.5em;
}

.hiddenIcon > svg {
  opacity: 0 !important;
}

.managerWarningIcon > svg {
  fill: $dusk-70 !important;
}

.tableRowSelected > div {
  background-color: rgba($dusk-70, 0.1) !important;
  margin-bottom: -2px;
  -webkit-box-shadow: inset 0px 0px 0px 2px $dusk-70;
  -moz-box-shadow: inset 0px 0px 0px 2px $dusk-70;
  box-shadow: inset 0px 0px 0px 2px $dusk-70;
  border-radius: 4px;
  z-index: 1;
  cursor: default;
}

.employeeRowName {
  margin-left: 20px;
}

/*
########## ELEMENT OVERRIDES ##########
*/
a {
  cursor: pointer;
}
