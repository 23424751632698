@import "../../../styles/color.module.scss";

.emptyWrapper {
  padding: 40px;
  display: flex;
  justify-content: center;
}

.emptyContent {
  max-width: 400px;
  text-align: center;
  h3 {
    padding: 8px;
    margin: 0;
  }
}

.emptyIllustrationWrapper {
  max-width: 200px;
  margin: 20px auto;
}

.checkBoxWrapperOuter {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.checkBoxWrapperInner {
  display: inline-block;
}

.leaveWrapper {
  // Expand table width for larger screens.
  // Workaround for the fluid prop of the StandardTemplate.
  @media(min-width: 1500px) {
    margin-left: -150px;
    width: 1500px !important;
  }

  @media (min-width: 768px) {
    :global .flx-card__table-body--sticky {
      padding-top: 1.6rem;
    }
  }
}

.failed {
  -webkit-text-fill-color: $dusk !important;
}

.errorIcon {
  vertical-align: middle;
  svg {
    fill: $red !important;
  }
}
