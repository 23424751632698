
@import "../../../styles/color.module.scss";


.newEmployeeContainer {
  display: flex;

}

.employeeDetails {
  flex: 2;
  border: 5px red;
  widows: 60%;
  background: blue;
}

.stepThreeContainer{

  background-color: $storm-11;
  padding-top: 0.5em;
  padding-bottom: 2em;
}

.stepThreeTitle{
 font-weight: 150;
padding-bottom: 0em;
  margin-bottom: 0em;
  margin-top: 1em;

}

.stepThreeField{
  font-weight: 50;
 margin-top: 0em;
  margin-bottom: 0em;
  padding-bottom: 0em;
}

.emptyWrapper {
  padding: 40px;
  display: flex;
  justify-content: center;
}

.emptyContent {
  text-align: center;
  h3 {
    padding: 8px;
    margin: 0;
  }
}
