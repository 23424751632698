@import "src/styles/color.module";

.checklistContainer {
 padding: 20px 0;
}

.tickIcon > svg {
  fill: $green !important;
}

.helpLinks {
  display: block;
}

.invalid {
  svg {
    fill: $red !important;
  }
  :hover {
    cursor: pointer;
  }
}
