.navigationContainer {
  & > .accent {
    background: linear-gradient(90deg, rgba(97,0,160,1) 0%, rgba(235,18,125,1) 100%);
    height: 3px;
  }
}

.breadcrumbContainer {
  padding: 0px 1.6rem!important;
}

.activeBreadCrumb{
  color:  #515e6d;
}

.inactiveBreadCrumb {
  color:  #515e6d;
  cursor: default;
  text-decoration: none!important;
}

.appBody {
  padding-top: 25px;
}

.App-logo {
  height: 20vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
  color: initial;
}

html, body {
  height: auto;
  background: #F3F4F5!important;
}

.appWrapper {
  right: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: left .4s ease-in-out;
}

@media (min-width: 768px) {
  :global(body.drawer-open) {
    .appWrapper {
      left: 290px;
    }
  }
}

@media (min-width: 1600px) {
  :global(body.drawer-open) {
    .appWrapper {
      left: 390px;
    }
  }
}
