@import "./_mixins.scss";

$white: #FFFFFF;
$green: #00AA65;
$red: #CE2554;

$thunder: #EBEEF1;
$thunder-black: #1B2830;
$storm-0: #FFFFFF;
$storm-6: #F3F4F5;
$storm-11: #E8EAEC;
$storm-17: #DCDFE1;
$storm-28: #C5C9CE;
$storm-50: #969EA7;
$storm-72: #687480;
$storm-100: #2E3E4F;

$dusk: #8241AA;
$dusk-70: #A476C1;

$crown: #806D25;
$pineapple: #b19836;
$golf: #00AA65;

$secondary-berry: #CE2554;
$azure: #2B74DF;

$tertiary-rouge: #B93499;
$tertiary-regal: #6100A5;
$tertiary-popcorn: #FFDC4B;
$tertiary-cabana: #77DFE7;
$tertiary-canday: #EC0677;
$tertiary-vista: #5C94E6;

$primary-gradient-first: #6100A5;
$primary-gradient-second: #EC0677;
$primary-gradient-horizontal: horizontal-gradient($primary-gradient-first, $primary-gradient-second);
$primary-gradient-vertical: vertical-gradient($primary-gradient-first, $primary-gradient-second);
$primary-gradient-radial: radial-gradient($primary-gradient-second, $primary-gradient-first);

$super-light-purple: #F6F0F8;

$label-beta: #E6DAEE;
$label-success: #CCEEE1;
$label-info: #D5E3F9;
$label-error: #F5D3DD;
$label-warning: #FAF3D9;
$label-light: $storm-6;
$label-critical: #515E6D;
