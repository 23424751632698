@import "../../../styles/color.module.scss";

.drawer {
  display: inline-block;
  background-color: $white;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  width: 0;

  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  transition: width .4s ease-in-out;
}

/*
  @FEELIX:
  To create square border as per design, instead of the rounded border that comes with the Aside component
*/
.drawer :global(.flx-aside__container) {
  border-radius: 0;
  margin-bottom: 0;
}

/*
  @FEELIX:
  To remove extra white space under accordion header in mobile view
*/
.drawer :global(.table-data__row .table-data__cell) {
  margin-bottom: 0;
}

.drawerOpen {
  margin-left: 0;
  transform: translateZ(0);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.drawHeaderText {
  text-align: center;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 500;
}

@media (min-width: 768px) {
  .drawer.drawerOpen {
    min-width: inherit;
    width: 290px;
  }

  .content {
    width: 290px;
  }
}

@media (min-width: 1200px) {
  .drawer.drawerOpen {
    box-shadow: none;
  }
}

@media (min-width: 1600px) {
  .drawer.drawerOpen {
    width: 390px;
  }

  .content {
    width: 390px;
  }
}
