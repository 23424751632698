@import "../../styles/color.module.scss";

.container, .maintenanceContainer {
  display: flex;
  background: #FFFFFF;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.maintenanceContainer p {
  margin: 0;
}

.maintenanceContainer .switchBusiness {
  margin-top: 20px;
}

.maintenanceContainer .customButton {
  margin-left: 5px;
}

.imageWrapper {
  margin-bottom: 30px;
}

.buttonContainer {
  padding: 24px
}

.customButton {
  color: #8241AA
}
