@import "../../../styles/color.module.scss";

.stepper {
  counter-reset: item;
  list-style: none;
  padding: .7rem;
  padding-bottom: 0;
  margin-bottom: 0;
}

.stepLink:hover {
  cursor: pointer;
  text-decoration: none;
}

.stepContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: .7rem;
  text-decoration: none;
  transition: background-color .2s;
  border-radius: 4px;

  &:hover {
    cursor: pointer !important;
    background-color: $super-light-purple;
    text-decoration: none;
  }
}

.stepText {
  color: $thunder-black;
  margin: 0;
  line-height: 1;
}

.stepNumber {
  padding: 6px;
  margin-right: 8px;
  background-color: $storm-50;
  min-width: 3rem;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.counterText {
  color: inherit;
  margin: 0;
  line-height: 1;
}

.disabled {
  & .stepNumber {
    background-color: $storm-28;
  }

  & .stepText {
    color: $storm-28;
  }


  & .stepContent {
    &:hover {
      cursor: auto !important;
      background-color: inherit;
    }
  }
}

.active {
  & .stepNumber {
    background-color: $dusk;
  }

  & .stepContent {
    background-color: $super-light-purple;

  }
}

.completed {
  & .stepNumber {
    background-color: $golf;
  }

  & .stepText {
    text-decoration: line-through;
  }
}

.image {
  width: 100%;
  height: 100%;
}

