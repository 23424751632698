@import "../../../styles/color.module.scss";

.footer {
  position: fixed;
  bottom: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerText {
  font-size: "1rem";
  color: $storm-50;
}
