// This cheeky little bit of code is a genius work around to overwrite
// Feelix styles while still localy scoping the changes just to the
// screen
// credit :- James Pickering
.rosterPage {
  background-color: transparent;

  :global .grid-cell__lg {
    width: 175px;
    min-width: 175px;
    flex-grow: unset;
    flex-basis: 175px;
  }

  :global .grid-cell__xs {
    width: 60px;
    min-width: 60px;
    flex-grow: unset;
    flex-basis: 60px;
  }

  :global .grid-cell {
    line-height: 24px;
    border-bottom-width: 1px;
  }

  :global .grid-cell-selected {
    border-bottom-width: 2px;
  }

  :global .grid-footer .grid-cell {
    font-size: 14px;
  }

  :global .filter-bar--sm .filter-bar__inner .flx-datepicker {
    width: 100%;
  }

  :global .grid-cell-invalid {
    border: 1px solid rgba(206, 37, 84, .5);
    margin-top: -1px;
  }

  :global .grid-group-header > .grid-cell-invalid {
    padding: 2px;
  }

  :global .grid-group-header > .grid-cell-invalid.grid-cell-selected {
    padding: 0;
    line-height: 26px;
  }

  :global .grid-group-header :local .cellPadding {
    background: #f3f4f5;
  }
}

.inputWrapper {
  width: 170px;
}

.headerText {
  font-weight: 600;
}

.headerAlignLeft {
  @extend .headerText;

  text-align: right;
}

.rowTitle {
  padding-left: 22px;
}

.rowHeaderTimes {
  font-weight: 200;
}

.rowTotal {
  text-align: right;
  font-size: 14px;
  font-weight: 600;
}


.cellPadding {
  padding: 0 4px;
}

.footerText {
  font-size: 14px;
  font-weight: 600;
}

.footerTextTitle {
  padding-left: 22px;
  font-weight: 600;
}

.footerTextTotal {
  text-align: right;
  font-weight: 600;
}

.publishContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 2rem 1.6rem 4rem;
}

.emptyWrapper {
  padding: 40px;
  display: flex;
  justify-content: center;
}

.emptyContent {
  max-width: 400px;
  text-align: center;
  h3 {
    padding: 8px;
    margin: 0;
  }
}

.emptyIllustrationWrapper {
  max-width: 200px;
  margin: 20px auto;
}
