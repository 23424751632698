.business {
    width: 200px !important;
}

.container {
    display: flex;
    /* or inline-flex */
    justify-content: space-between;
}

.filler {
    flex-grow: 1;
    text-align: center
}

.divider {
    display: inline-block;
    width: 2px;
    background-color: lightgrey;
    margin: 0 10px;
    height: 30px;
}

.left {
    text-align: left;
}

.select {
    text-align: left;
    margin-right: 5px;
}

.right {
    text-align: right;
    margin-top: 15px;
}

.searchSection {
    flex: 1;
    flex-direction: 'row';
    justify-content: 'center';
    align-items: 'center';
    background-color: '#fff';
}

.searchIcon {
    padding: 10;
}

.inputSearch {
    flex: 1;
    padding-top: 10;
    padding-right: 10;
    padding-bottom: 10;
    padding-left: 0;
    background-color: '#fff';
    color: '#424242';
}

.businessListTable {
    min-height: 500px
}