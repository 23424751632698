@import "../color.module.scss";

.labelBeta {
    background-color: $label-beta!important;
}

.labelSuccess {
    background-color: $label-success!important;
}

.labelInfo {
    background-color: $label-info!important;
}

.labelError {
    background-color: $label-error!important;
}

.labelWarning {
    background-color: $label-warning!important;
}

.labelLight {
    background-color: $label-light!important;
}

.labelCritical {
    background-color: $label-critical!important;
}