@import "../../../styles/color.module.scss";

.onboardingWizardWrapper {
  :global .flx-card {
    box-shadow: none !important;
    min-width: 50%;
  }

  :global .flx-aside {
    min-width: 100%;
  }

  :global .flx-step--complete .flx-step__description {
    text-decoration: line-through;
  }
}

.onboardingWizardContent {
  :global .flx-stepper {
    padding: .7rem;
    align-items: stretch;
  }


  :global .flx-stepper__desktop.flx-stepper__desktop--vertical {
    min-width: 100%;
  }


  :global .flx-step {
    padding: .7rem;
  }

  :global .flx-step--active {
    background-color: $super-light-purple;
  }

}

.purpleContainer {
  padding: 24px 1rem 0;
  border-radius: 4px;
  background-color: $super-light-purple;
}

.completeButtonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.completeText {
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  padding-right: 1.2rem;
}

.completeTextBody {
  font-size: 14px;
}

.welcomeTitle {
  font-size: 18px;
  margin-bottom: 8px;
}

.welcomeBody {
  font-size: 14px;
  padding-bottom: 24px;
}
