@import "../../../styles/color.module.scss";

.tickIcon > svg {
  fill: $green !important;
}

.pending {
  -webkit-text-fill-color: $pineapple !important;
}

.failed {
  -webkit-text-fill-color: $dusk !important;
}

.errorIcon {
  vertical-align: middle;
  svg {
    fill: $red !important;
  }
}

.timesheetWrapper {
  // Expand table width for larger screens.
  // Workaround for the fluid prop of the StandardTemplate.
  @media(min-width: 1500px) {
    margin-left: -150px;
    width: 1500px !important;
  }

  // Show chevron when accordion is selected.
  :global .table-data--selected .table-data__actions {
    opacity: 1;
    pointer-events: all;
  }

  // Place filter bar options in front of accordion header.
  :global .combobox-menu {
    z-index: 1000;
  }

  :global .accordionHeader {
    .table-data__header {
      .table-data__cell {
        padding-left: 0;

        .checkbox {
          padding-left: 16px;
        }
      }
    }
  }
}

.emptyWrapper {
  padding: 40px;
  display: flex;
  justify-content: center;
}

.emptyContent {
  max-width: 400px;
  text-align: center;
  h3 {
    padding: 8px;
    margin: 0;
  }
}

.emptyIllustrationWrapper {
  max-width: 200px;
  margin: 20px auto;
}

.topLevelApproval {
  padding-top: 10px;
}

.alertLink {
  font-weight: 700;
}
