@import "../../../styles/color.module";

.modal {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C5C9CEAA;
  z-index: 99;
  overflow: hidden;
}

.show {
  @extend .modal;
  
  width: 100vw;
  height: 100vh;
}

.hide {
  @extend .modal;

  width: 0;
  height: 0;
}

.placeholder {
  padding-bottom: 100px;
}
