.gridContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  align-items: center;
  justify-items: center;

  .gridItem {
    padding-top: 10px;
    img {
      height: 172px;
      width: 129px;
      border-radius: 4px;
    }
  }
}
